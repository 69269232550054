import React from "react";

const Star = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1122 1122"
    style={{ enableBackground: "new 0 0 1122 1122" }}
  >
    <g>
      <g>
        <path
          style={{ fill: "#F3CC30" }}
          d="M570.5,252.5l93.8,190c1.5,3.1,4.5,5.3,8,5.8l209.7,30.5c8.7,1.3,12.2,11.9,5.9,18.1L736.1,644.8
        c-2.5,2.4-3.6,5.9-3,9.4L768.8,863c1.5,8.7-7.6,15.2-15.4,11.2l-187.5-98.6c-3.1-1.6-6.8-1.6-9.9,0l-187.5,98.6
        c-7.8,4.1-16.9-2.5-15.4-11.2L389,654.1c0.6-3.4-0.5-6.9-3-9.4L234.2,496.9c-6.3-6.1-2.8-16.8,5.9-18.1l209.7-30.5
        c3.4-0.5,6.4-2.7,8-5.8l93.8-190C555.4,244.7,566.6,244.7,570.5,252.5z"
        />
        <g>
          <path
            style={{ fill: "#F7DB5E" }}
            d="M561,296.4l-83.6,161.9c-4.4,8.5-12.8,14.2-22.3,15l-181.4,16.6l191.7,9c12.2,0.6,23.3-6.8,27.5-18.2
            L561,296.4z"
          />
        </g>
        <path
          style={{ fill: "#EDBD31" }}
          d="M357.3,838.9l-4.1,24c-1.5,8.7,7.6,15.2,15.4,11.2l187.5-98.6c3.1-1.6,6.8-1.6,9.9,0l187.5,98.6
        c7.8,4.1,16.9-2.5,15.4-11.2L733,654.1c-0.6-3.4,0.5-6.9,3-9.4l151.7-147.9c6.3-6.1,2.8-16.8-5.9-18.1l-70.2-10.2
        C698.1,672.4,524.2,779.8,357.3,838.9z"
        />
      </g>
    </g>
  </svg>
);

export default Star;
