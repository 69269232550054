import { graphql } from "gatsby";
import React from "react";
import Star from "../components/partials/star";
import Seo from "../components/seo";

const ReviewTemplate = ({ data }) => {
  if (!data.wpReview) return null;

  const currentReview = data.wpReview;
  const stars = [];
  const rating = currentReview.review?.rating || 5;
  for (let index = 0; index < rating; index++) {
    stars.push(<Star key={index} />);
  }

  return (
    <>
      <Seo
        title={currentReview.seo.title}
        description={currentReview.seo.metaDesc}
      />
      <div className="flex flex-wrap default-page">
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
          <h1>{currentReview.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: currentReview.content }} />
          <p>
            <a
              href={process.env.GOOGLE_REVIEWS_PAGE}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="text-white no-underline flex w-32 my-4"
              title={`${rating} Star Google Review`}
            >
              {stars}
            </a>
          </p>

          <p>
            <a
              href={process.env.GOOGLE_REVIEWS_PAGE}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded`}
              title={`${rating} Star Google Review`}
            >
              See Review on Google
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ReviewTemplate;

export const reviewQuery = graphql`
  query ($id: String!) {
    wpReview(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      review {
        rating
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
